import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavbarCollapse } from "react-bootstrap";
import { FaBars, FaBlog, FaHome, FaPhone, FaQuestion, FaVideo } from "react-icons/fa";

const Navbar = () => {
    const [showCategories, setShowCategories] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Fetch categories from the server
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://www.suencu.com/fetch_category.php');
                const data = await response.json();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        if (categories.length === 0) fetchCategories();
    }, [categories]);

    return (
        <nav className="navbar navbar-expand-lg rounded" style={{ backgroundColor: "#006400" }}>
            <div className="container-fluid w-100">
                <div className="nav nav-pills w-100">
                    <Link to="/" className="flex-fill text-lg-center text-white nav-link align-items-center">
                        <span className="d-none d-lg-inline">Ana Sayfa </span><FaHome />
                    </Link>
                    <button
                        className="flex-fill text-lg-center text-white nav-link align-items-center"
                        onClick={() => setShowCategories(!showCategories)}>
                        <span className="d-none d-lg-inline">Kategoriler </span><FaBars />
                    </button>
                    <Link to="/video" className="flex-fill text-lg-center text-white nav-link align-items-center">
                        <span className="d-none d-lg-inline">Videolar </span><FaVideo />
                    </Link>
                    <Link to="/faq" className="flex-fill text-lg-center text-white nav-link align-items-center">
                        <span className="d-none d-lg-inline">S.S.S. </span><FaQuestion />
                    </Link>
                    <Link to="/blog" className="flex-fill text-lg-center text-white nav-link align-items-center">
                        <span className="d-none d-lg-inline">Blog </span><FaBlog />
                    </Link>
                    <Link to="/contact" className="flex-fill text-lg-center text-white nav-link align-items-center">
                        <span className="d-none d-lg-inline">İletişim </span><FaPhone />
                    </Link>
                    {showCategories && categories.length > 0 && (
                        <NavbarCollapse in={showCategories}>
                            <ul className="navbar-nav mb-2 mt-1 w-100 p-2 rounded" style={{ backgroundColor: "#ffffe6" }}>
                                {categories.map((category, index) => (
                                    <li key={index} className="flex-lg-fill text-lg-center nav-link align-items-center">
                                        <Link
                                            to={`/category/${category.category_name}`}
                                            className="flex-lg-fill text-lg-center nav-link align-items-center">
                                            {category.category_name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </NavbarCollapse>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
