import {FaEnvelope, FaInstagramSquare, FaPhoneSquare} from "react-icons/fa";
import axios from 'axios';
import React, {useState} from "react";
import {Helmet} from "react-helmet";


const ContactPage = () => {
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        email: '',
        name: '',
        surname: ''
    });

    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/contact.php', formData);
            setMessage(response.data.message);
        } catch (error) {
            console.error('There was an error!', error);
            setMessage('Mesaj gönderilirken bir hata oluştu.');
        }
    };

    const listStyle = {
        day: {
            transition: "transform 0.15s ease-in-out"
        }, "&:hover": {
            transform: "scale3d(1.05, 1.05, 1)"
        }
    };

    return (
        <>
            <Helmet>
                <title>{`Suencu`}</title>
                <meta name="description" content="İletişim sayfası"/>

                <meta property="og:title" content="İletişim - Suencu"/>
                <meta property="og:description" content="İletişim sayfası"/>
                <meta property="og:url" content={`https://www.suencu.com/contact`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content="İletişim - Suencu"/>
                <meta name="twitter:description" content="İletişim sayfası"/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>
            <h3 className="mt-2 mb-2 p-2">İletişim Bilgileri:</h3>
            <div className="d-flex justify-content-start">
                <div className="col-lg-6 col-8">
                    <ul className={"list-group list-group-flush border-1 rounded shadow-sm mt-2 mb-2 p-3" + listStyle.day}>
                        <li className="list-group-item m-1 rounded shadow-sm bg-success-subtle"
                            style={{"onHover": "transform: \"scale3d(1.05, 1.05, 1)\""}}>
                            <div className="d-flex justify-content-between align-items-center">
                                <FaPhoneSquare/>
                                <a className="text-decoration-none" href="tel:+905365522420"
                                   target="_blank">+90 (536) 552 24 20</a>
                            </div>
                        </li>
                        <li className="list-group-item m-1 rounded shadow-sm bg-success-subtle">
                            <div className="d-flex justify-content-between align-items-center">
                                <FaEnvelope/>
                                <a className="text-decoration-none" href="mailto:info@suencu.com"
                                   target="_blank">info@suencu.com</a>
                            </div>
                        </li>
                        <li className="list-group-item m-1 rounded shadow-sm bg-success-subtle">
                            <div className="d-flex justify-content-between align-items-center">
                                <FaInstagramSquare/>
                                <a className="text-decoration-none" href="https://www.instagram.com/suencutr"
                                   target="_blank">@suencutr</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="border border-1 mt-3 mb-1 rounded">
                <h3 className="mt-4 mb-2 p-2">Bizimle İletişime Geçin:</h3>
                <div className="d-flex justify-content-center">
                    <div className="col-8">
                        {message && <div className="mt-3 alert alert-info">{message}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Başlık</label>
                                <input type="text" className="form-control" id="title" name="title"
                                       value={formData.title} onChange={handleChange} required/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="content" className="form-label">Mesaj</label>
                                <textarea className="form-control" id="content" name="content" value={formData.content}
                                          onChange={handleChange} rows="4" required></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">E-posta</label>
                                <input type="email" className="form-control" id="email" name="email"
                                       value={formData.email} onChange={handleChange} required/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Ad</label>
                                <input type="text" className="form-control" id="name" name="name" value={formData.name}
                                       onChange={handleChange} required/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="surname" className="form-label">Soyad</label>
                                <input type="text" className="form-control" id="surname" name="surname"
                                       value={formData.surname} onChange={handleChange} required/>
                            </div>
                            <div className="d-flex justify-content-end align-items-center p-1">
                                <button type="submit" className="btn btn-primary rounded">Gönder</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactPage;