import VideoItem from "./videos/VideoItem";
import React, {useEffect, useState} from "react";
import SearchBox from "./videos/searchBox";
import {Helmet} from "react-helmet";

const VideosPage = () => {
    const [videos, setVideos] = useState([]);
    const [filteredVideos, setFilteredVideos] = useState([]);
    const [videosLoaded, setVideosLoaded] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(`https://www.suencu.com/fetch_videos.php`);
                const data = await response.json();

                if (response.ok) {
                    setVideos(data);

                    setVideosLoaded(data.reduce((acc, video) => {
                        acc[video.video_id] = false;
                        return acc;
                    }, {}));

                } else {
                    setError(data.message || 'Videolar yüklenirken bir hata oluştu.');
                }
            } catch (error) {
                setError('Videolar yüklenirken bir hata oluştu.');
            } finally {
                setLoading(false);
                setError(false);
            }
        };

        fetchVideos();
    }, []);

    const setVideoLoaded = (videoId) => {
        const newVideosLoaded = {...videosLoaded, [videoId]: true};

        setVideosLoaded(newVideosLoaded);
    };

    return (
        <div className="col">
            <Helmet>
                <title>{`Suencu`}</title>
                <meta name="description" content="Videolar sayfası"/>

                <meta property="og:title" content="Videolar - Suencu"/>
                <meta property="og:description" content="Videoları görüntüleme sayfası"/>
                <meta property="og:url" content={`https://www.suencu.com/video`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content="Videolar - Suencu"/>
                <meta name="twitter:description" content="Videoları görüntüleme sayfası"/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>
            <div className="row">
                <h3 className="mt-2 mb-2 p-2">Videolar</h3>
            </div>

            <SearchBox searchQuery={searchQuery}
                       setSearchQuery={setSearchQuery}
                       videos={videos}
                       setFilteredVideos={setFilteredVideos}/>

            <div className="row align-items-md-stretch">
                {loading && <div className="mt-3 alert alert-info">Yükleniyor...</div>}
                {error && <div className="mt-3 alert alert-danger">{error}</div>}
                {!loading && !error && filteredVideos.length > 0 && filteredVideos.map(video => (
                    <VideoItem key={video.video_id}
                               video={video}
                               searchQuery={searchQuery}
                               videoLoaded={videosLoaded[video.video_id]}
                               setVideoLoaded={setVideoLoaded}/>
                ))}
                {!loading && !error && (videos.length === 0 || filteredVideos.length === 0) &&
                    <div className="mt-3 alert alert-info">Video bulunamadı!</div>}
            </div>
        </div>
    );
};

export default VideosPage;