import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import CarouselItem from "./CarouselItem";

const CarouselMain = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchCarousels = async () => {
            try {
                const response = await axios.get('https://www.suencu.com/fetch_carousels.php');
                const fetchedItems = response.data.map(item => ({
                    src: `/carousels/${item.image_path}`,
                    caption: item.carousel_name || '',
                    desc: item.description || '',
                    target: item.external_url || "MAIN_PAGE",
                    outer: !!item.external_url,
                    text_class: "text-black"
                }));

                setItems(fetchedItems);
                setLoading(false);
            } catch (error) {
                setError('Error fetching carousel items');
                setLoading(false);
            }
        };

        fetchCarousels();
    }, []);

    if (loading) return <div className="mt-3 alert alert-info">Yükleniyor...</div>;
    if (error) return <div className="mt-3 alert alert-danger">{error}</div>;

    const handleSelect = (selectedIndex) => {
        setCurrentIndex(selectedIndex);
    };

    return (
        <Carousel className="bg-success-subtle rounded" activeIndex={currentIndex} onSelect={handleSelect}>
            {items.length > 0 && items.map((item, index) => (
                <Carousel.Item key={index} interval={5000}>
                    <CarouselItem
                        data={item}
                        loadImage={index === currentIndex || index === currentIndex + 1 || index === currentIndex - 1}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default CarouselMain;
