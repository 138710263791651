import React from "react";
import {Routes, Route, Link} from "react-router-dom";
import Navbar from "./components/Navbar";
import MainPage from "./components/MainPage";
import ContactPage from "./components/ContactPage";
import {FaEnvelope, FaEye, FaInfoCircle, FaInstagramSquare, FaLock, FaPhoneSquare} from "react-icons/fa";
import FAQPage from "./components/FAQPage";
import CategoryPage from "./components/CategoryPage";
import BlogsPage from "./components/BlogsPage";
import './App.css';
import backgroundImage from './background.png';
import VideosPage from "./components/VideosPage";
import NotFoundPage from "./NotFoundPage";
import VizyonPage from "./components/VizyonPage";
import InfoPage from "./components/InfoPage";
import KVKKPage from "./components/KVKKPage";
import BlogPage from "./components/BlogPage";
import ProductPage from "./components/items/ProductPage";
import VideoPage from "./components/videos/VideoPage";


function App() {
    return (
        <div className="container-fluid py-3"
             style={{"height": "100vh", "backgroundImage": `url(${backgroundImage})`, backgroundRepeat: "repeat"}}>
            <div className="container-lg bg-soft rounded h-100 overflow-y-visible overflow-x-auto">
                <div className="col-lg">
                    <div className="row">
                        <Navbar/>
                    </div>
                    <div className="row">
                        <Routes>
                            <Route path="/" element={<MainPage/>}/>
                            <Route path="/contact" element={<ContactPage/>}/>
                            <Route path="/faq" element={<FAQPage/>}/>
                            <Route path="/category" element={<CategoryPage/>}/>
                            <Route path="/category/:categoryName" element={<CategoryPage/>}/>
                            <Route path="/blog" element={<BlogsPage/>}/>
                            <Route path="/blog/:blogID" element={<BlogPage/>}/>
                            <Route path="/video" element={<VideosPage/>}/>
                            <Route path="/video/:videoID" element={<VideoPage/>}/>
                            <Route path="/vizyon" element={<VizyonPage/>}/>
                            <Route path="/misyon" element={<VizyonPage/>}/>
                            <Route path="/hakkimizda" element={<InfoPage/>}/>
                            <Route path="/kvkk" element={<KVKKPage/>}/>
                            <Route path="/product/:itemID" element={<ProductPage/>}/>

                            <Route path="*" element={<NotFoundPage/>}/>
                        </Routes>
                    </div>
                    <footer className="row p-3 bottom-0" style={{"backgroundColor": "rgba(255, 255, 204, 0.8)"}}>
                        <div
                            className="col-12 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">

                            <div className="col-10 col-lg-3 mt-2 mb-2 d-flex justify-content-center">
                                <img src="https://www.suencu.com/Logo.png" alt="Logo"
                                     style={{"width": "200px", "height": "auto"}}/>
                            </div>

                            <ul className="list-group rounded mt-2 mb-2 col-10 col-lg-3">
                                <li className="list-group-item" style={{"backgroundColor": "#ffffe6"}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <FaInfoCircle/>
                                        <Link className="text-decoration-none footer-link"
                                              to="/hakkimizda">Hakkımızda</Link>
                                    </div>
                                </li>
                                <li className="list-group-item" style={{"backgroundColor": "#ffffe6"}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <FaEye/>
                                        <Link className="text-decoration-none footer-link" to="/vizyon">Vizyon ve
                                            Misyon</Link>
                                    </div>
                                </li>
                                <li className="list-group-item" style={{"backgroundColor": "#ffffe6"}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <FaLock/>
                                        <Link className="text-decoration-none footer-link" to="/kvkk">KVKK</Link>
                                    </div>
                                </li>
                            </ul>

                            <ul className="list-group rounded mt-2 mb-2 col-10 col-lg-3">
                                <li className="list-group-item" style={{"backgroundColor": "#ffffe6"}}>
                                    <div className="d-flex justify-content-start align-items-center">
                                        Bizimle İletişime Geçin!
                                    </div>
                                </li>
                                <li className="list-group-item" style={{"backgroundColor": "#ffffe6"}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <FaPhoneSquare/>
                                        <a className="text-decoration-none footer-link" href="tel:+905365522420"
                                           target="_blank">+90 (536) 552 24 20</a>
                                    </div>
                                </li>
                                <li className="list-group-item" style={{"backgroundColor": "#ffffe6"}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <FaEnvelope/>
                                        <a className="text-decoration-none footer-link" href="mailto:info@suencu.com"
                                           target="_blank">info@suencu.com</a>
                                    </div>
                                </li>
                                <li className="list-group-item" style={{"backgroundColor": "#ffffe6"}}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <FaInstagramSquare/>
                                        <a className="text-decoration-none footer-link"
                                           href="https://www.instagram.com/suencutr"
                                           target="_blank">@suencutr</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </footer>

                </div>
            </div>
        </div>
    );
}

export default App;
