import {FaBullseye, FaEye, FaGlobe, FaLeaf, FaTree} from "react-icons/fa";
import {Helmet} from "react-helmet";
import React from "react";


const VizyonPage = () => {
    return (
        <div className="flex-column">
            <Helmet>
                <title>{`Suencu`}</title>
                <meta name="description" content="Vizyon ve Misyon sayfası"/>

                <meta property="og:title" content="Vizyon ve Misyon - Suencu"/>
                <meta property="og:description" content="Vizyon ve Misyon sayfası"/>
                <meta property="og:url" content={`https://www.suencu.com/vizyon`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content="Vizyon ve Misyon - Suencu"/>
                <meta name="twitter:description" content="Vizyon ve Misyon sayfası"/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>
            <div className="row p-3 mt-3">
                <div className="col-md-6 mb-3">
                    <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                        <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                            <h5 className="text-center"><FaEye/><span className="mx-3 h-100">Vizyon</span></h5>
                        </div>
                        <div className="card-body text-start text-wrap">
                            <p>
                                Sürdürülebilir, çevreye duyarlı veya kültürel temalı ürünlerle farklılık yaratarak SKA
                                (SÜRDÜRÜLEBİLİR KALKINMA AMAÇLARI) doğrultusunda sektörde öncü rol oynamaktır.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-3">
                    <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                        <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                            <h5 className="text-center"><FaBullseye/><span className="mx-3 h-100">Misyon</span></h5>
                        </div>
                        <div className="card-body text-start text-wrap">
                            <p>
                                Dinamik ve yenilikçi bakış açısıyla standardı yüksek, kalıcı değer yaratan
                                uygulamalarla
                                tüketicilere doğru hizmet ve ürünleri sunarak topluma fayda sağlamaktır.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row p-3 mt-3">
                    <div className="col-md-4 mb-3">
                        <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                            <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                                <h5 className="text-center"><FaLeaf/><span
                                    className="mx-3 h-100"><b>Su</b>stainable</span>
                                </h5>
                            </div>
                            <div className="card-body text-center text-wrap">
                                <p>
                                    Gelecek nesillerin ihtiyaçlarını karşılayabileceği bir ortak gelecek hayal ediyoruz!
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                            <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                                <h5 className="text-center"><FaTree/><span
                                    className="mx-3 h-100"><b>En</b>vironmental</span>
                                </h5>
                            </div>
                            <div className="card-body text-center text-wrap">
                                <p>
                                    Yeşili, maviyi doğanın tüm renklerini çok seviyoruz!
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card border border-1 shadow-sm p-0" style={{"minHeight": "25vh"}}>
                            <div className="card-header" style={{"backgroundColor": "#ffcc00"}}>
                                <h5 className="text-center"><FaGlobe/><span
                                    className="mx-3 h-100"><b>Cu</b>ltural</span>
                                </h5>
                            </div>
                            <div className="card-body text-center text-wrap">
                                <p>
                                    Ortak mirasımızda yer alanları ortak geleceğimize taşımak istiyoruz!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VizyonPage;
