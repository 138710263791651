import React, { useState, useEffect } from "react";
import axios from "axios";
import {Helmet} from "react-helmet";

const FAQPage = () => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await axios.get('/fetch_faqs.php');
                setFaqs(response.data);
                setLoading(false);
            } catch (error) {
                setError('Error fetching FAQs');
                setLoading(false);
            }
        };

        fetchFaqs();
    }, []);

    if (loading) return <div className="mt-3 alert alert-info">Yükleniyor...</div>;
    if (error) return <div className="mt-3 alert alert-info">{error}</div>;

    return (
        <>
            <Helmet>
                <title>{`Suencu`}</title>
                <meta name="description" content="S.S.S. sayfası"/>

                <meta property="og:title" content="S.S.S. - Suencu"/>
                <meta property="og:description" content="Sıkça sorulan sorular sayfası"/>
                <meta property="og:url" content={`https://www.suencu.com/faq`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content="S.S.S. - Suencu"/>
                <meta name="twitter:description" content="Sıkça sorulan sorular sayfası"/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>
            <h3 className="mt-2 mb-2 p-2">Sıkça Sorulan Sorular</h3>
            <div className="col bg-light-subtle m-2 p-3 overflow-scroll border border-2 rounded" style={{"height": "75vh"}}>
                {faqs.length > 0 ? faqs.map((faq, index) => (
                    <div key={index} className="row mt-3">
                        <div className="col">
                            <div className="row">
                                <div className="d-inline-flex justify-content-start">
                                    <p className="text-start text-wrap col-lg-4 col-6 m-1 p-2 border border-1 shadow-sm rounded" style={{"backgroundColor": "#80ff80"}}>{faq.question}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-inline-flex justify-content-end">
                                    <p className="text-start text-wrap col-lg-4 col-6 m-1 p-2 border border-1 shadow-sm rounded" style={{"backgroundColor": "#e6b800"}}>{faq.answer}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : (
                    <div className="mt-3 alert alert-info">Soru-Cevap bulunamadı.</div>
                )}

            </div>
        </>
    );
};

export default FAQPage;