import React from 'react';
import './KVKK.css';
import {Helmet} from "react-helmet";

const KVKKPage = () => {
    return (
        <div className="container mt-3 p-3 kvkk-page">
            <Helmet>
                <title>{`Suencu`}</title>
                <meta name="description" content="KVKK sayfası"/>

                <meta property="og:title" content="KVKK - Suencu"/>
                <meta property="og:description" content="Kişisel verilerin korunması kanunu ile ilgili metin"/>
                <meta property="og:url" content={`https://www.suencu.com/kvkk`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content="KVKK - Suencu"/>
                <meta name="twitter:description" content="Kişisel verilerin korunması kanunu ile ilgili metin"/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>
            <h2 className="mb-4 text-center">KİŞİSEL VERİLERİNİN İŞLENMESİNE DAİR AYDINLATMA METNİ</h2>
            <p>
                İşbu Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni ile, 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) 10. maddesi uyarınca, Madenler Mah. Medeniyet Sk. Gürcan Sitesi No: 13 B İç Kapı No: 8 Ümraniye/ İstanbul adresinde mukim, METEHAN GÖKALP KABLAN (“Şirket”) olarak kişisel veri işleme faaliyetlerimiz hakkında sizleri bilgilendirmek ve aydınlatmak isteriz.
            </p>
            <h4>1. Veri Sorumlusu ve Temsilcisi</h4>
            <p>
                1.1. Tarafınıza ait kişisel veriler, Şirket tarafından veri sorumlusu sıfatıyla aşağıda açıklanan amaçlar doğrultusunda ve bunlarla sınırlı olarak, hukuka ve dürüstlük kurallarına uygun suretle işlenebilecek, kaydedebilecek, saklanabilecek, sınıﬂandırılabilecek, güncellenebilecek ve mevzuatın izin verdiği hallerde veyahut işlendikleri amaçla sınırlı olarak üçüncü kişilere açıklanabilecek/aktarabilecektir.
            </p>
            <h4>2. Kişisel Verilerinizin İşlenme Amacı</h4>
            <p>
                Kişisel verileriniz, KVKK’da öngörülen temel ilkelere uygun olarak açık rızanız olmasa dahi; <br/>
                <ul>
                    <li>Mevzuattan kaynaklanan yükümlülüklerin ve yasal bildirimlerin yerine getirilmesi, yasal takip süreçlerinin yürütülmesi,</li>
                    <li>Satın almaya yönelik bilgilerin toplanması ve satılan ürünün teslim amacıyla hazırlanarak gönderilmesi,</li>
                    <li>Websitesinde söz konusu ticari faaliyete yönelik risk analizi ve yönetimi,</li>
                    <li>Süreç denetim ve şüpheli işlem araştırması süreçlerinin yönetimi,</li>
                    <li>Pazarlama amacı taşımayan; hizmet kalitesi ve müşteri memnuniyetinin ölçümlenmesi, asistans hizmetlerinin değerlendirilmesi, şikâyet yönetimi, ilgili veriler doğrultusunda müşterilerin segmente edilmesi, anket çalışmalarının yapılması,</li>
                    <li>Gelen aramaların kayıt altına alınması, kayıtların hizmet kalitesinin arttırılması amacıyla kullanılması,</li>
                    <li>Hizmetin sunumuna yönelik dış hizmet tedarik edilen firmalardan her türlü destek hizmetlerinin temin edilmesi,</li>
                    <li>Gerçek ve tüzel üçüncü kişilerden gelecek taleplere verilecek cevapların hazırlanması, </li>
                </ul>
            </p>
            <p>
                Açık Rızanız bulunması halinde ise; <br/>
                Paylaşmış olduğunuz Kişisel Veriler; satış-pazarlama iletişimi ve genel bilgilendirmeler amacıyla, çerez politikamızda belirttiğimiz şekilde diğer şirketlerden topladığımız bilgiler ile birleştirerek hizmetlerimizin içerik ve tanıtımlarımızın geliştirilmesi ve kişileştirilmesi veya yeni hizmetler sunabilmek amacıyla ve her türlü iletişim mesajlarının gönderilmesi amacıyla kullanabilir. <br/>
                İlgili kişi, pazarlama amaçlı bu tanıtımları almamak veya söz konusu reklam-kişiselleştirme, yeniden hedeﬂeme uygulamalarında yer almak istemediği takdirde, taleplerini her zaman internet sitesinde yer alan iletişim bilgilerinden ulaşarak da iletebilecektir. <br/>
            </p>
            <h4>3. Kişisel Verilerinizin Aktarılması</h4>
            <p>
                Kişisel Verileriniz, aynı zamanda sadece ilgili kişiye sağlanacak hizmetlerin daha iyi sunulabilmesi, daha güvenli bir şekilde saklanması, korunması ve olası gönderilerinizin sağlıklı şekilde teslim edilmesi, telefon, sms ve/veya e-posta yoluyla bildirimlerimizin zamanında ulaştırılabilmesi amacıyla, sözleşme ilişkisi içinde olduğumuz, veri güvenliği konusundaki hassasiyetimizi paylaşan, ilgili mevzuat hükümlerine riayet eden; yerli ya da yabancı ülkelerde yerleşik 3. kişilerle, yalnızca ihtiyaç durumunda ve gerekli ölçüde paylaşılacaktır.
            </p>
            <h4>4. Yurtdışına Veri Aktarımı</h4>
            <p>
                Şirket tarafından, hukuka uygun olarak işlenen kişisel verileriniz, açık rızanız doğrultusunda veya KVKK m. 5/2 ile KVKK m. 6/3 hükümlerinde öngörülen durumların varlığı halinde açık rızanız temin edilmeksizin, Kişisel Verileri Koruma Kurulu (“Kurul”) tarafından yeterli korumaya sahip olduğu tespit ve ilan edilen yabancı ülkelerde mukim kişi veyahut kuruluşlara veya kişisel verilerin aktarıldığı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt ettiği ve kurulun izninin temin edilebildiği hallerle sınırlı olmak kaydıyla Kurulca tespit ve ilan edilenlerin dışındaki ülkelere aktarılabilecektir.
            </p>
            <h4>5. Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Sebebi</h4>
            <p>
                Kişisel verileriniz, faaliyetlerimizi yürütmek amacıyla Şirket tarafından form, e-posta, telefon, sms, web sitemiz, mobil uygulamamız, kargo, yazışmalar, saha çalışmaları, sosyal medya, 3. kişiler, posta, web ara yüzü, kamera kaydı, asistans firmalar ve sair kanallar vasıtasıyla ve yukarıda belirtilen hukuki sebeplere dayanarak toplanmaktadır. Bu çerçevede kişisel verileriniz, KVKK m. 4/2 hükmünde öngörülen ilkeler ışığında açık rıza temini suretiyle veya KVKK m. 5/2 ve KVKK m. 6/3 hükümlerinde öngörülen durumların varlığı halinde, açık rıza temin edilmeksizin işlenebilmekte ve aktarılabilmektedir.
            </p>
            <h4>6. KVKK’nın 11. Maddesi Uyarınca Sahip Olduğunuz Haklar</h4>
            <p>
                Şirket tarafından KVKK m. 11 kapsamında tarafınızca yapılacak başvuruların ivedilikle, etkin ve kapsamlı bir şekilde değerlendirilebilmesi ve çözümlenebilmesi adına internet sitemizde yer alan “İletişim” sayfamızda bildirilen adresimize ıslak imzalı olarak iadeli taahhütlü posta yolu ile iletebilirsiniz. Şirket, niteliğine göre, talebinizi mümkün olan en kısa sürede ve her halükarda en geç otuz (30) gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, başvurunuzun incelenmesi ve sonuçlandırılmasına yönelik olarak gerçekleştirilecek işlemlerin ayrıca bir maliyeti gerektirmesi halinde, Kurul tarafından belirlenen tarifedeki ücretler tarafınıza fatura edilecektir.
            </p>
            <p>
                Yukarıda anılan madde hükmü uyarınca sahip olduğunuz haklar aşağıdaki gibidir: <br/>
                <ul>
                    <li>Kişisel verinizin Şirket tarafından işlenip işlenmediğini öğrenme,</li>
                    <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                    <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                    <li>KVKK m. 7’de öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
                    <li>KVKK m. 11 (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>Kişisel verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonuç doğurması durumunda itiraz etme,</li>
                    <li>Kişisel verilerinizin ilgili mevzuata aykırı olarak işlenmesi sebebiyle herhangi bir zarara uğramanız hâlinde zararın giderilmesini talep etme.</li>
                </ul>
            </p>
            <h4>7.  Kişisel Veri Saklama Süresi</h4>
            <p>
                Kişisel verileriniz, size bildirilen amaçlar ve kapsam dışında kullanılmamak kaydı ile gerekli tüm bilgi güvenliği tedbirleri de alınarak işlenecek ve yasal saklama süresince veya böyle bir süre öngörülmemişse işleme amacının gerekli kıldığı süre boyunca saklanacak ve işlenecektir. Bu süre sona erdiğinde, kişisel verileriniz silinme, yok edilme ya da anonimleştirme yöntemleri ile Şirketimizin veri akışlarından çıkarılacaktır.
            </p>
            <h4>8.  Bilgilerinizin Güncellenmesi Talebi</h4>
            <p>
                Şirket nezdinde işlenen kişisel verilerinizin doğru ve güncel olması gerekmektedir. Bu nedenle, kişisel veri niteliğindeki bilgilerinizde herhangi bir değişiklik meydana gelmesi halinde, bu hususu internet sitemizde yer alan “İletişim” sayfamızda bildirilen e-mail adresimize, Üyelik esnasında kullandığınız iletişim e-posta adresinizden göndereceğiniz bir e-posta yolu ile bildirebilirsiniz.
            </p>
        </div>
    );
};

export default KVKKPage;
