import React, {useState, useEffect} from "react";
import logo from "../../logo.jpg";
import {Image} from "react-bootstrap";
import {FaShoppingCart} from "react-icons/fa";
import './item.css';
import {useParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

const ProductPage = () => {
    const {itemID} = useParams();
    const [item, setItem] = useState(null);
    const [imagePath, setImagePath] = useState(logo);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const logoPath = "https://www.suencu.com/Logo.png"

    useEffect(() => {
        // Fetch item details from API
        const fetchItem = async () => {
            try {
                const response = await fetch(`https://www.suencu.com/fetch_item.php?itemID=${itemID}`);

                if (response.status === 404) {
                    throw new Error("Ürün bulunamadı.");
                }

                if (!response.ok) {
                    throw new Error("Ürün yüklenirken bir hata oluştu.");
                }

                const data = await response.json();
                setItem(data);

            } catch (err) {
                setError(err.message);
                if (err.message === "Ürün bulunamadı.") {
                    setTimeout(() => {
                        navigate("/");
                    }, 3000);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchItem();
    }, [itemID, navigate]);

    useEffect(() => {
        if (item) {
            setImagePath(item.image_path ? `https://www.suencu.com/items/${item.image_path}` : logo);
        }
    }, [item]);

    return (
        <div className="row mt-1 p-5 d-flex justify-content-center">
            <Helmet>
                <title>{loading ? 'Ürün yükleniyor...' : error ? 'Ürün bulunamadı - Suencu' : `${item.item_name} - Suencu`}</title>
                <meta name="description"
                      content={loading ? 'Ürün detayları yükleniyor' : error ? 'Ürün detayları bulunamadı' : item.description}/>

                <meta property="og:title"
                      content={loading ? 'Yükleniyor...' : error ? 'Ürün bulunamadı' : item.item_name}/>
                <meta property="og:description"
                      content={loading ? 'Ürün detayları yükleniyor' : error ? 'Ürün detayları bulunamadı' : item.description}/>
                <meta property="og:image" content={loading ? logoPath : item.image_path || logoPath}/>
                <meta property="og:url" content={`https://www.suencu.com/product/${itemID}`}/>
                <meta property="og:type" content="product"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title"
                      content={loading ? 'Yükleniyor...' : error ? 'Ürün bulunamadı' : item.item_name}/>
                <meta name="twitter:description"
                      content={loading ? 'Ürün detayları yükleniyor' : error ? 'Ürün detayları bulunamadı' : item.description}/>
                <meta name="twitter:image" content={loading ? logoPath : item.image_path || logoPath}/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>
            {loading && <div className="mt-3 alert alert-info">Yükleniyor...</div>}
            {error && <div className="mt-3 alert alert-danger">{error}</div>}

            {!loading && item && (
                <div className="col-lg-6 col-sm-8 item d-md-flex">
                    <div className="bg-white border border-1 rounded shadow-sm">
                        <div className="row bg-light align-items-center">
                            <Image
                                src={imagePath}
                                className="card-img p-1 border border-1 rounded shadow-sm item-hover-img"
                                loading="lazy"
                            />
                        </div>
                        <div className="row overflow-y-auto bg-white p-1" style={{"max-height": "300vh"}}>
                            <div className="card-body">
                                <h5 className="card-title p-2">
                                    {item.item_name}
                                </h5>
                                <div className="card-text flex-md-grow-1">
                                    <p className="item-hover-p p-2">
                                        {item.description}
                                    </p>
                                    <div className="row m-2">
                                        <a className="btn text-white w-100 mx-1" href={item.trendyol_path || "#"}
                                           target="_blank" rel="noopener noreferrer"
                                           style={{"backgroundColor": "#006400"}}>
                                            <span>Satın Al </span>
                                            <FaShoppingCart/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductPage;
