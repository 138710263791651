import React, {useEffect, useState} from 'react';
import {Button, Image} from "react-bootstrap";
import "./video.css";
import {FaPlay} from "react-icons/fa";
import logo from "../../logo.jpg";
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";


const VideoPage = () => {
    const {videoID} = useParams();

    const [video, setVideo] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [previewImage, setPreviewImage] = useState(logo);
    const [isReel, setIsReel] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const logoPath = "https://www.suencu.com/Logo.png"

    useEffect(() => {
        // Fetch item details from API
        const fetchVideo = async () => {
            try {
                const response = await fetch(`https://www.suencu.com/fetch_video.php?videoID=${videoID}`);

                if (response.status === 404) {
                    throw new Error("Video bulunamadı.");
                }

                if (!response.ok) {
                    throw new Error("Video yüklenirken bir hata oluştu.");
                }

                const data = await response.json();
                setVideo(data);

            } catch (err) {
                setError(err.message);
                if (err.message === "Video bulunamadı.") {
                    setTimeout(() => {
                        navigate("/video");
                    }, 3000);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchVideo();
    }, [videoID, navigate]);

    useEffect(() => {
        if (video) {
            setPreviewImage(`https://www.suencu.com/videos/${video.video_id}/preview.jpg`);
            setIsReel(video.video_type === "Reels")
        }
    }, [video]);

    const handlePlay = () => {
        setLoaded(true);
    };

    return (
        <div className="row mt-1 p-5 d-flex justify-content-center">
            <Helmet>
                <title>{loading ? 'Video yükleniyor...' : error ? 'Video bulunamadı - Suencu' : `${video.title} - Suencu`}</title>
                <meta name="description"
                      content={loading ? 'Video detayları yükleniyor' : error ? 'Video detayları bulunamadı' : video.description}/>

                <meta property="og:title"
                      content={loading ? 'Yükleniyor...' : error ? 'Video bulunamadı' : video.title}/>
                <meta property="og:description"
                      content={loading ? 'Video detayları yükleniyor' : error ? 'Video detayları bulunamadı' : video.description}/>
                <meta property="og:image" content={loading ? logoPath : previewImage || logoPath}/>
                <meta property="og:url" content={`https://www.suencu.com/video/${videoID}`}/>
                <meta property="og:type" content="video"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title"
                      content={loading ? 'Yükleniyor...' : error ? 'Video bulunamadı' : video.title}/>
                <meta name="twitter:description"
                      content={loading ? 'Video detayları yükleniyor' : error ? 'Video detayları bulunamadı' : video.description}/>
                <meta name="twitter:image" content={loading ? logoPath : previewImage || logoPath}/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>
            {loading && <div className="mt-3 alert alert-info">Yükleniyor...</div>}
            {error && <div className="mt-3 alert alert-danger">{error}</div>}
            {!loading && video && (
                <div className={`col-${isReel ? '6' : '8'} d-md-flex`}>
                    <div className="card p-2 m-3 border border-1 rounded video-card">
                        {!loaded &&
                            <div className="video-placeholder bg-light-subtle d-flex justify-content-center">
                                <Image
                                    src={previewImage} // Placeholder
                                    loading="lazy"
                                    className={`card-img-top p-2 border border-1 rounded ${isReel ? "item-reels" : "item-video"}`}
                                />
                                <Button variant="primary" className="play-button" onClick={handlePlay}>
                                    <div className="d-flex justify-content-center align-items-center"><FaPlay/></div>
                                </Button>
                            </div>
                        }
                        {loaded &&
                            <div className="d-flex align-items-center justify-content-center">
                                <video src={"https://www.suencu.com/videos/" + video.video_id + "/" + video.video_path}
                                       className={`card-img-top p-1 border border-1 rounded ${isReel ? "item-reels" : "item-video"}`}
                                       controls/>
                            </div>
                        }
                        <div className="card-body">
                            <h5 className="card-title">{video.title}</h5>
                            {!loaded && video.description && (
                                <div className="card-text video-card-text flex-md-grow-1">
                                    <p className="video-p">{video.description}</p>
                                </div>
                            )}
                            {loaded && video.description && (
                                <div className="card-text video-card-text flex-md-grow-1">
                                    <p className="video-p-loaded">{video.description}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>)}
        </div>
    );
};

export default VideoPage;