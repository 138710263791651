import React, { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { countMatch } from "../../utils";

const SearchBox = ({ searchQuery, setSearchQuery, blogs, setFilteredBlogs }) => {
    const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

    // Debounce logic
    useEffect(() => {
        const handler = setTimeout(() => {
            setSearchQuery(debouncedQuery);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedQuery, setSearchQuery]);

    useEffect(() => {
        if (searchQuery.trim()) {
            const filteredAndSorted = blogs
                .map((blog) => {
                    const titleMatchCount = countMatch(blog.title, searchQuery);
                    const textMatchCount = countMatch(blog.text, searchQuery);
                    const authorMatchCount = countMatch(blog.name + " " + blog.surname, searchQuery);

                    // Weighted sum: title and author matche have weight 1, description matches have weight 0.5
                    const relevanceScore = (titleMatchCount * 1) + (authorMatchCount * 1) + (textMatchCount * 0.5);

                    return { ...blog, relevanceScore };
                })
                .filter(blog => blog.relevanceScore > 0) // Filter out items with no relevance
                .sort((a, b) => b.relevanceScore - a.relevanceScore); // Sort by relevance score

            setFilteredBlogs(filteredAndSorted);
        } else {
            setFilteredBlogs(blogs); // Show all items if no search query
        }
    }, [searchQuery, blogs, setFilteredBlogs]);

    return (
        <div className="mb-3">
            <div className="d-flex justify-content-end">
                <div className="col-lg-4 col-8 d-flex align-items-center">
                    <input
                        type="text"
                        className="form-control mx-1"
                        placeholder="Blog ara..."
                        value={debouncedQuery}
                        onChange={(e) => setDebouncedQuery(e.target.value)}
                    />
                    <FaSearch className="ml-2" style={{ cursor: 'pointer' }} />
                </div>
            </div>
        </div>
    );
};

export default SearchBox;
