import React, {useState, useEffect} from 'react';
import Item from "./items/Item";
import {useParams} from "react-router-dom";
import SearchBox from "./items/searchBox";

const CategoryPage = () => {
    const {categoryName} = useParams();
    const [items, setItems] = useState([]);
    const [itemsLoaded, setItemsLoaded] = useState({});
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        // Fetch items for the given category
        const fetchItems = async () => {
            try {
                const response = await fetch(`https://www.suencu.com/fetch_items.php?category=${encodeURIComponent(categoryName)}`);
                const data = await response.json();

                if (response.ok) {
                    setItems(data);
                    setItemsLoaded(data.reduce((acc, item) => {
                        acc[item.item_id] = false;
                        return acc;
                    }, {}));

                } else {
                    setError(data.message || 'Ürünler yüklenirken bir hata oluştu.');
                }
            } catch (error) {
                console.error('Error fetching items:', error);
                setError('Ürünler yüklenirken bir hata oluştu.');
            } finally {
                setLoading(false);
                setError(false);
            }
        };

        fetchItems();
    }, [categoryName]);

    const setItemLoaded = (itemId) => {
        const newItemsLoaded = {...itemsLoaded, [itemId]: true};

        setItemsLoaded(newItemsLoaded);
    };

    return (
        <>
            <div className="col">
                <div className="row">
                    <h3 className="mt-2 mb-2 p-2">{categoryName}</h3>
                </div>

                <SearchBox searchQuery={searchQuery}
                           setSearchQuery={setSearchQuery}
                           items={items}
                           setFilteredItems={setFilteredItems}/>

                <div className="row align-items-md-stretch">
                    {loading && <div className="mt-3 alert alert-info">Yükleniyor...</div>}
                    {error && <div className="mt-3 alert alert-danger">{error}</div>}
                    {!loading && !error && filteredItems.length > 0 && filteredItems.map(item => (
                        <Item key={item.item_id}
                              item={item}
                              searchQuery={searchQuery}
                              setItemLoaded={setItemLoaded}
                              itemLoaded={itemsLoaded[item.item_id]}/>
                    ))}
                    {!loading && !error && (items.length === 0 || filteredItems.length === 0) &&
                        <div className="mt-3 alert alert-info">Ürün bulunamadı!</div>}
                </div>
            </div>
        </>
    );
};

export default CategoryPage;