import React, {useEffect, useState} from "react";
import BlogShort from "./Blog/BlogShort";
import SearchBox from "./Blog/searchBox";
import {Helmet} from "react-helmet";


const BlogsPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredBlogs, setFilteredBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch('https://www.suencu.com/fetch_blogs.php');
                const data = await response.json();
                if (response.ok) {
                    setBlogs(data);
                } else {
                    setError(data.message || 'Bloglar yüklenirken bir hata oluştu.');
                }
            } catch (error) {
                setError('Bloglar yüklenirken bir hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <div className="flex-column">
            <Helmet>
                <title>{`Suencu`}</title>
                <meta name="description" content="Blog görüntüle"/>

                <meta property="og:title" content="Bloglar - Suencu"/>
                <meta property="og:description" content="Blogları görüntüle"/>
                <meta property="og:url" content={`https://www.suencu.com/blog`}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content="Bloglar - Suencu"/>
                <meta name="twitter:description" content="Blogları görüntüle"/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>
            <div className="row mb-3 mt-1 p-2">
                <div className="d-flex justify-content-between">
                    <h3 className="mt-2 mb-2 p-2">En son yazılarımızı keşfedin</h3>
                </div>

                <SearchBox searchQuery={searchQuery}
                           setSearchQuery={setSearchQuery}
                           blogs={blogs}
                           setFilteredBlogs={setFilteredBlogs}/>
            </div>

            <div className="row align-items-md-stretch">
                {loading && <div className="mt-3 alert alert-info">Yükleniyor...</div>}
                {error && <div className="mt-3 alert alert-danger">{error}</div>}

                {(blogs.length === 0 || filteredBlogs.length === 0) && !loading &&
                    <div className="mt-3 alert alert-info">Blog bulunamadı.</div>}
                {!loading && filteredBlogs.length > 0 && filteredBlogs.map((value, index) => (
                    <BlogShort
                        key={index}
                        blogItem={value}
                        searchQuery={searchQuery}
                    />
                ))}
            </div>
        </div>
    );
};

export default BlogsPage;
