import React, { useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import './carousel.css';
import { Link } from "react-router-dom";

const CarouselItem = ({ data, loadImage }) => {
    const imgRef = useRef(null); // Görseli takip edeceğimiz ref

    useEffect(() => {
        const imgElement = imgRef.current;
        if (!imgElement) return;

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting || loadImage) {
                    imgElement.src = imgElement.dataset.src;
                    observer.unobserve(imgElement);
                }
            });
        }, {
            rootMargin: "0px 0px 300px 0px",
            threshold: 0.1
        });

        observer.observe(imgElement);

        return () => observer.disconnect();
    }, [data.src, loadImage]);

    return (
        <div className="my-carousel-item">
            {"outer" in data && data.outer ? (
                <a href={data.target} target="_blank" rel="noopener noreferrer" className="d-block w-100">
                    <Image
                        ref={imgRef}
                        className="d-block mx-auto carousel-img"
                        data-src={"https://www.suencu.com/" + data.src}
                        alt={data.caption}
                    />
                </a>
            ) : (
                <Link to={`/${data.target}`} className="d-block w-100">
                    <Image
                        ref={imgRef}
                        className="d-block mx-auto carousel-img"
                        data-src={"https://www.suencu.com/" + data.src}
                        alt={data.caption}
                    />
                </Link>
            )}
            {data.caption || data.desc ? (
                <div className="carousel-caption">
                    {data.caption && <h3><b>{data.caption}</b></h3>}
                    {data.desc && <h5>{data.desc}</h5>}
                </div>
            ) : null}
        </div>
    );
};

export default CarouselItem;
