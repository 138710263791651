import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import {Helmet} from "react-helmet";

const NotFoundPage = () => {
    return (
        <div className="row p-3 mt-3">
            <Helmet>
                <title>{`Suencu`}</title>
                <meta name="description" content="404 sayfa bulunamadı"/>

                <meta property="og:title" content="404 sayfa bulunamadı - Suencu"/>
                <meta property="og:description" content="404 sayfa bulunamadı"/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content="404 sayfa bulunamadı"/>
                <meta name="twitter:description" content="404 sayfa bulunamadı"/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <div className="p-4">
                    <h1 className="display-1 text-danger">404</h1>
                    <h2 className="mb-3">Oops! Sayfa Bulunamadı</h2>
                    <p className="mb-4">Aradığınız sayfa mevcut değil ya da taşınmış olabilir.</p>
                    <Link to="/" className="btn btn-primary btn-lg">
                        Ana Sayfaya Dön <FaHome />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
