import React from "react";

export const countMatch = (name, query) => {
    const lowerCaseName = name.trim().toLowerCase();
    const lowerCaseQuery = query.trim().toLowerCase();

    let count = 0;
    for (let i = 0; i < lowerCaseName.length;) {
        const matchIndex = lowerCaseName.indexOf(lowerCaseQuery, i);
        if (matchIndex !== -1) {
            count++;
            i = matchIndex + lowerCaseQuery.length;
        } else {
            break;
        }
    }

    return count;
};

export const highlightText = (text, query) => {
    if (!query) return text;

    const regex = new RegExp(`(${query})`, 'gi'); // Create a case-insensitive regex for the query
    const parts = text.split(regex); // Split the text by the search query, keeping the case intact

    return parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? <strong key={index}>{part}</strong> : part
    );
};