import React, {useEffect, useState} from "react";
import {FaArrowLeft} from "react-icons/fa";
import {Link, useParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

const BlogPage = () => {
    const {blogID} = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await fetch(`https://www.suencu.com/fetch_blog.php?blogID=${blogID}`);

                if (response.status === 404) {
                    throw new Error("Blog bulunamadı");
                }

                if (!response.ok) {
                    throw new Error("Blog yüklenirken bir hata oluştu");
                }

                const data = await response.json();
                setBlog(data);

            } catch (err) {
                setError(err.message);
                if (err.message === "Blog bulunamadı") {

                    setTimeout(() => {
                        navigate("/blog");
                    }, 3000);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchBlog();
    }, [blogID, navigate]);

    return (
        <div className="flex-column">
            <Helmet>
                <title>{loading ? 'Blog yükleniyor...' : error ? 'Blog bulunamadı - Suencu' : `${blog.title} - Suencu`}</title>
                <meta name="description"
                      content={loading ? 'Blog detayları yükleniyor' : error ? 'Blog detayları bulunamadı' : blog.text.slice(0, 150) + '...'}/>

                <meta property="og:title" content={loading ? 'Yükleniyor...' : error ? 'Blog bulunamadı' : blog.title}/>
                <meta property="og:description"
                      content={loading ? 'Blog detayları yükleniyor' : error ? 'Blog detayları bulunamadı' : blog.text.slice(0, 150) + '...'}/>
                <meta property="og:url" content={`https://www.suencu.com/blog/${blogID}`}/>
                <meta property="og:type" content="article"/>
                <meta property="og:site_name" content="Suencu"/>

                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title"
                      content={loading ? 'Yükleniyor...' : error ? 'Blog bulunamadı' : blog.title}/>
                <meta name="twitter:description"
                      content={loading ? 'Blog detayları yükleniyor' : error ? 'Blog detayları bulunamadı' : blog.text.slice(0, 150) + '...'}/>
                <meta name="twitter:site" content="@suencu"/>
            </Helmet>

            <div className="row p-5">
                {loading && <div className="mt-3 alert alert-info">Yükleniyor...</div>}
                {error && <div className="mt-3 alert alert-danger">{error}</div>}
                {!loading && blog && (
                    <div className="card border border-1 shadow-sm p-0">
                        <div className="card-header d-flex justify-content-between align-items-center"
                             style={{backgroundColor: "#ffcc00"}}>
                            <h5 className="m-0 text-wrap">{blog.title}</h5> {/* Blog başlığı */}
                            <Link className="btn rounded text-white" style={{backgroundColor: "#006400"}} to="/blog">
                                <FaArrowLeft/><span className="d-none d-lg-inline"> Geri</span>
                            </Link>
                        </div>
                        <div className="card-body overflow-scroll" style={{height: "75vh"}}>
                            <div className="row d-flex justify-content-end">
                                <small
                                    className="text-muted">{`${blog.name} ${blog.surname} - ${new Date(blog.create_date).toLocaleDateString()}`}</small> {/* Yazar ve tarih */}
                            </div>

                            {blog.text.split('\n').map((line, index) => (
                                <p key={index} className="text-start text-wrap"
                                   dangerouslySetInnerHTML={{__html: line}}></p>
                            ))}
                        </div>
                    </div>)}
            </div>
        </div>
    );
};

export default BlogPage;
