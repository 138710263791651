import React, {useEffect, useState} from "react";
import {FaArrowRight} from "react-icons/fa";
import "./Blog.css"
import {Link} from "react-router-dom";
import {highlightText} from "../../utils";

const highlightInnerText = (text, query) => {
    if (!query) return text;

    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part) =>
        part.toLowerCase() === query.toLowerCase() ? `<b>${part}</b>` : part
    ).join(''); // Join without commas
};


const BlogShort = ({blogItem, searchQuery}) => {
    const maxLength = 250;
    const [textToShow, setTextToShow] = useState("");

    useEffect(() => {
        const stripHtmlTags = (str) => {
            return str.replace(/<\/?[^>]+(>|$)/g, "");
        };

        const cleanText = stripHtmlTags(blogItem["text"]).trim();
        setTextToShow(cleanText.length > maxLength ? cleanText.substring(0, maxLength) + "..." : cleanText);
    }, [blogItem]);

    return (
        <div className="col-lg-6 col-12 blog-short d-md-flex">
            <div className="card border border-1 shadow-sm rounded p-3 m-3">
                <div className="card-header d-flex justify-content-between align-items-center"
                     style={{backgroundColor: "#ffcc00"}}>
                    <h5 className="m-0">{highlightText(blogItem["title"], searchQuery)}</h5>
                    <small
                        className="text-muted">{`${highlightText(blogItem["name"], searchQuery)} ${highlightText(blogItem["surname"], searchQuery)} - ${new Date(blogItem["create_date"]).toLocaleDateString()}`}</small>
                </div>
                <div className="card-body d-flex flex-column flex-md-grow-1">
                    <div className="card-text flex-md-grow-1">
                        <p className="text-start text-wrap" dangerouslySetInnerHTML={{__html: highlightInnerText(textToShow, searchQuery)}}></p>
                        <div className="mt-auto d-flex justify-content-end align-items-end">
                            <Link className="btn text-white" style={{backgroundColor: "#006400"}}
                                    to={"/blog/" + blogItem["blog_id"]}>
                                <span className="d-none d-lg-inline">Devamını Oku </span>
                                <span className="d-inline d-lg-none">Oku </span>
                                <FaArrowRight/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogShort;
